import { Flex, Text, Center, Icon } from '@chakra-ui/react';

import { ReactComponent as CheckIcon } from '../../ds2/assets/Check.svg';
import { fadeIn } from '../utils';

interface CheckboxWithTextProps {
  isChecked: boolean;
  onChange: () => void;
  text: string;
}

export default function CheckboxWithText({ isChecked, onChange, text }: CheckboxWithTextProps) {
  return (
    <Flex w="fit-content" gap="5px" align="center" cursor="pointer" onClick={onChange}>
      <Center boxSize="15px" border="1px" borderColor="greyNatural" borderRadius="2px">
        {isChecked && <Icon animation={`${fadeIn} 0.1s`} boxSize="8px" as={CheckIcon} />}
      </Center>

      <Text userSelect="none" fontSize="chatTinyText" fontWeight={300} color="greyNatural">
        {text}
      </Text>
    </Flex>
  );
}
