import { Flex, FlexProps, Image, ImageProps } from '@chakra-ui/react';

interface UsersImagesProps extends FlexProps {
  imagesSrc: string[];
  imageProps?: ImageProps;
}

export default function UsersImages({ imagesSrc, imageProps, ...rest }: UsersImagesProps) {
  const imageSize = 18;
  return (
    <Flex
      pos="relative"
      gap="3px"
      flexShrink={0}
      {...rest}
      w={imagesSrc.length === 1 ? 'fit-content' : `${imageSize + (imagesSrc.length - 1) * 9}px`}
    >
      {imagesSrc.map((src, index, arr) => {
        return (
          <Image
            key={src + index}
            src={src}
            boxSize={`${imageSize}px`}
            pos="relative"
            zIndex={-index}
            right={index !== 0 ? 3 * index : 0}
            pr={index !== arr.length - 1 && arr.length !== 1 ? '2px' : '0px'}
            bg="white"
            borderRadius="4px"
            {...imageProps}
          />
        );
      })}
    </Flex>
  );
}
