import { useState, useCallback } from 'react';
import {
  ChatMessageRequest,
  ChatRequest,
  ChatResponse,
  ChatStatusResponse,
  ChatStatusSuccessResponse,
} from '@worknet/models';

import { HostPlatformContext } from './api/types';

export type StreamingMode = 'Text' | 'Tool' | 'None';

export type TaskRequest = { actionId: string; content?: string };
type StreamingState = {
  loading?: boolean;
  error?: Error;
  data?: ChatStatusSuccessResponse;
  taskRequest?: TaskRequest;
  streaming?: {
    mode: StreamingMode;
    value: string;
  };
};

function sleep(ms: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, ms));
}

export function usePolledStreaming(hostPlatformContext: HostPlatformContext) {
  const [state, setState] = useState<StreamingState>({});
  const { apiFn, sourceContext, customContext, groupingId } = hostPlatformContext;

  const doFetch = useCallback(
    async (taskRequest: TaskRequest, chatId?: string, shared?: boolean) => {
      setState({ loading: true, taskRequest });

      try {
        const metadata = await apiFn<ChatResponse, ChatRequest | ChatMessageRequest>(
          chatId ? `/chats/${chatId}/messages?mode=polling` : '/chats?mode=polling',
          {
            actionId: taskRequest.actionId,
            message: taskRequest.content ?? '',
            sourceContext,
            customContext,
            groupingId,
            shared,
          }
        );

        let count = 0;
        const pollDelayMs = 250;
        const maxPendingPollCycles = 10_000 / pollDelayMs;

        // eslint-disable-next-line no-constant-condition
        while (true) {
          count++;
          await sleep(pollDelayMs);

          const chatStatus = await apiFn<ChatStatusResponse>(
            `/chats/${metadata.chatId}/messages/${metadata.messageId}`
          );

          if (chatStatus.status === 'pending') {
            if (count >= maxPendingPollCycles) {
              throw new Error(
                `AI Layer pending timeout for chat ${metadata.chatId} msg ${metadata.messageId}`
              );
            }
            continue;
          }

          if (chatStatus.status === 'failed') {
            throw new Error(chatStatus.error);
          }

          if (chatStatus.tool) {
            setState((prev) => {
              return {
                ...prev,
                error: undefined,
                streaming: { mode: chatStatus.tool as StreamingMode, value: chatStatus.content },
              };
            });
          }

          if (chatStatus.status === 'done') {
            setState((prev) => ({
              ...prev,
              loading: false,
              error: undefined,
              data: chatStatus,
            }));
            break;
          }
        }
      } catch (error) {
        console.error(error);
        setState((prev) => ({ ...prev, loading: false, error: error as Error }));
      }
    },
    [apiFn, sourceContext, customContext, groupingId]
  );

  return {
    doFetch,
    ...state,
  };
}
